import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { images, useCreditCardValidator } from "react-creditcard-validator";
import { toast } from "react-toastify";
import { Col, FormGroup, Button, Row } from "reactstrap";
import { LoadingSpinner } from "../../../../../components/Common/LoadingSpinner/LoadingSpinner";
import lock from "../../../../../assets/images/lock.svg";
import onvo from "../../../../../assets/images/logo/onvo.png";
import visa from "../../../../../assets/images/logo/visa_mastercard.png";
import { CouponRespository } from "../../../../../repositories/coupon";
import { useIsDesktop } from "../../../../../helpers/useIsDesktop";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { useHistory } from "react-router-dom";
import dropDown from "../../../..//../assets/images/drop-down.svg";
import "./index.css";
import { useParams } from "react-router-dom";
import { AUTH_USER_KEY } from "../../../../../routes/constants";
import CountriesRepository from "../../../../../repositories/countries";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
const full = 1;

export const CardForm = ({
  handlerManagePayment,
  basePrice,
  discount,
}) => {
  const intl = new Intl.NumberFormat();
  const currentUser = JSON.parse(localStorage.getItem(AUTH_USER_KEY));
  const [cities, setCities] = useState([]);
  const currentId = currentUser.store.country_id.id;
  const { t } = useTranslation();
  const showPrice = basePrice - basePrice * 0.5;


  useEffect(() => {
    if (currentId === 2) {
      new CountriesRepository()
        .listCitiesByCountry(currentId)
        .then((data) => {
          setCities(data.map((city) => ({ label: city.name, value: city.id })));
        })
        .catch((error) => console.log(error));
    }
  }, [currentId]);

  const currentCity = cities.filter(
    (city) => city.value === currentUser.store.city_id
  );

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const expDateValidate = (year) => {
    if (Number(year) > 40) {
      return "Fecha de expiración no puede ser mayor a 2040";
    }
    return;
  };

  const {
    getCardNumberProps,
    getCardImageProps,
    getCVCProps,
    getExpiryDateProps,
    meta: { erroredInputs, cardType },
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

  const [dataError, setDataError] = useState(false);
  const couponRef = useRef(null);
  const cardNumberProps = getCardNumberProps();
  const cardNameRef = useRef(null);
  const nameUserRef = useRef(null);
  const lastNameUserRef = useRef(null);
  const idTypeRef = useRef(null);
  const idRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const postCodeRef = useRef(null);

  const cardExpiryDateProps = getExpiryDateProps({ placeholder: "MM/YY" });
  const cardCvcProps = getCVCProps({ placeholder: "CVV" });

  const [loading, setLoading] = useState(false);
  const [validateCoupon, setValidateCoupon] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponMessage, setCouponMessage] = useState(null);
  const [couponType, setCouponType] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { planId } = useParams();

  const getPaymentMethodInfo = () => {
    setDataError(false);

    if (currentId === 1 || currentId === 3) {
      const cardNumber = cardNumberProps.ref.current.value;
      const cardName = cardNameRef.current.value;
      const cardExpiryDate = cardExpiryDateProps.ref.current.value;
      const cvv = cardCvcProps.ref.current.value;

      const isCardNumberValid =
        cardNumber.trim() !== "" && !erroredInputs.cardNumber;
      const isCardNameValid = cardName.trim() !== "";
      const isExpiryDateValid =
        cardExpiryDate.trim() !== "" && !erroredInputs.expiryDate;
      const isCvcValid = cvv.trim() !== "" && !erroredInputs.cvc;

      const isFormValid =
        isCardNumberValid && isCardNameValid && isExpiryDateValid && isCvcValid;

      if (isFormValid) {
        const [month, year] = cardExpiryDate.split(" / ");
        const fullYear = "20" + year;

        return {
          type: "card",
          paymentMethod: {
            number: cardNumber.replace(/ /g, ""),
            expMonth: parseInt(month),
            expYear: parseInt(fullYear),
            cvv: cvv,
            holderName: cardName,
          },
          subscriptionId: Number(planId),
        };
      }
    }

    if (currentId === 2) {
      const userName = nameUserRef.current.value;
      const lastName = lastNameUserRef.current.value;
      const idType = idTypeRef.current.value;
      const id = idRef.current.value;
      const cellphone = phoneRef.current.value;
      const email = emailRef.current.value;
      const postCode = postCodeRef.current.value;

      const cardNumber = cardNumberProps.ref.current.value;
      const cardName = cardNameRef.current.value;
      const cardExpiryDate = cardExpiryDateProps.ref.current.value;
      const cvv = cardCvcProps.ref.current.value;

      const isCardNumberValid =
        cardNumber.trim() !== "" && !erroredInputs.cardNumber;
      const isCardNameValid = cardName.trim() !== "";
      const isExpiryDateValid =
        cardExpiryDate.trim() !== "" && !erroredInputs.expiryDate;
      const isCvcValid = cvv.trim() !== "" && !erroredInputs.cvc;

      const isFormValid =
        isCardNumberValid && isCardNameValid && isExpiryDateValid && isCvcValid;

      if (isFormValid) {
        const [month, year] = cardExpiryDate.split(" / ");

        return {
          type: "card",
          id_type: idType,
          id: id,
          first_name: userName,
          last_name: lastName,
          cellphone: cellphone,
          email: email,
          subscriptionId: Number(planId),
          paymentMethod: {
            card_number: cardNumber.replace(/ /g, ""),
            expiration_date: `${month}/${year}`,
            franchise: cardType.type.toLocaleUpperCase() || "AMERICAN EXPRESS",
            header_user_agent: "test-user-agent",
            line1: "No Aplica",
            line2: "No Aplica",
            line3: "No Aplica",
            post_code: postCode,
            cvv: String(cvv),
            city: currentCity[0].label,
            country: currentUser.store.country_id.name,
            state: currentCity[0].label,
            holder_name: cardName,
          },
        };
      }
    } else {
        setDataError(true);

    }
  };

  const handlerPayment = async () => {
    setLoading(true)
    const payload = getPaymentMethodInfo();
    if (payload) {
      await handlerManagePayment(payload);
    }
    setLoading(false)
  };




  const onSave = async () => {
    if (validateCoupon) {
      const repository = new CouponRespository();
      let response;
      if (currentId === 1 || currentId === 3) {
        if (
          validateCoupon.coupon_type === 5 &&
          validateCoupon.coupon_type_name === "BEEMAYCA"
        ) {
          if (currentId === 1 || currentId === 3) {
            response = await repository.aplicateCoupon(
              couponRef.current.value,
              {
                type: "card",
                paymentMethod: {
                  number: "424242424242424242",
                  expMonth: 12,
                  expYear: 29,
                  cvv: "895",
                  holderName: "Promo Mayca",
                },
              }
            );
          }
        } else if (validateCoupon.coupon_type === full) {
          response = await repository.aplicateCoupon(couponRef.current.value, {
            type: "card",
            paymentMethod: {
              number: "",
              expMonth: 0,
              expYear: 0,
              cvv: "",
              holderName: "",
            },
          });
        } else {
          const paymentMethod = getPaymentMethodInfo();
          if (paymentMethod) {
            response = await repository.aplicateCoupon(
              couponRef.current.value,
              paymentMethod
            );
          }
        }
      }

      if (currentId === 2) {
        if (
          validateCoupon.coupon_type === 5 &&
          validateCoupon.coupon_type_name === "BEE50"
        ) {
          const paymentMethod = getPaymentMethodInfo();
          if (paymentMethod) {
            response = await repository.aplicateCouponCol(
              couponRef.current.value,
              paymentMethod
            )
          }
          } else if (validateCoupon.coupon_type === 5 &&
            validateCoupon.coupon_type_name === "BEEBASICO") {
                response = await repository.aplicateCouponCol(
                  couponRef.current.value, {
                    type: "card",
                    id_type: "CC",
                    id: "88888888",
                    first_name: "John",
                    last_name: "Doe",
                    cellphone: "88888888",
                    email: "test@correo.com",
                    subscriptionId: Number(planId),
                    paymentMethod: {
                      card_number: "4513076106055348",
                      expiration_date: "06/26",
                      franchise: "VISA",
                      header_user_agent: "test-user-agent",
                      line1: "No Aplica",
                      line2: "No Aplica",
                      line3: "No Aplica",
                      post_code: "050001",
                      cvv: "159",
                      city: currentCity[0].label,
                      country: currentUser.store.country_id.name,
                      state: currentCity[0].label,
                      holder_name: "John Doe",
                    },
                  }
                )
          } else {
            const paymentMethod = getPaymentMethodInfo();
            if (paymentMethod) {
              response = await repository.aplicateCouponCol(
                couponRef.current.value,
                paymentMethod
              );
            }
          }
      }

      if (response) {
        if (response.status === 201) {
          Swal.fire({
            title: "",
            text: t("The payment method has been successfully updated"),
            icon: "success",
            confirmButton: true,
            confirmButtonColor: "#1186FA",
          });
          if (isDesktop) {
            history.push("/profile-details");
          } else {
            history.push("/finish-up");
          }
        } else {
          toast.info(response.response.data.message);
        }
      }
    } else {
      await handlerPayment();
    }
    setLoading(false);
  };



  const applyCupon = async () => {
    if (!couponRef.current.value) {
      return;
    }
    const repository = new CouponRespository();



    try {
      setCouponLoading(true);
      const response = await repository.validate(couponRef.current.value);
      if (response.data.coupon_type) {
        setCouponType(response.data.coupon_type);
      }
      if (response.data.coupon_type === 1) {
        setCouponMessage("*Membresía gratis de por vida");
        setDataError(false);
      } else if (response.data.coupon_type === 2) {
        setCouponMessage("*Mismo precio de por vida");
      } else if (response.data.coupon_type === 3) {
        setCouponMessage("*Mismo precio de por vida");
      } else if (response.data.coupon_type === 4) {
        setCouponMessage("*Mismo precio de por vida");
      } else if (response.data.coupon_type === 5) {
        if (response.data.coupon_type_name === "BEEMAYCA") {
          if (planId == 30) {
            setCouponMessage("Plan Mensual Basico Gratis De Por Vida");
          } else {
            setCouponMessage("El cupón ingresado no es válido para este plan.");
            setValidateCoupon(false);
          }
        }
        if (response.data.coupon_type_name === "BEE50") {
          setCouponMessage("50% De Descuento");
        }

        if (response.data.coupon_type_name === "BEEBASICO") {
          setCouponMessage("Plan Básico Gratis De Por Vida");
        }
      } else if (response.response.status in [404, 400]) {
        setCouponMessage(response.response.data.message);
        setValidateCoupon(false);
      }
      setValidateCoupon(response?.data);
    } catch (e) {
      setCouponMessage("El cupón ingresado no es válido.");
      setValidateCoupon(false);
      console.error(e);
    } finally {
      setCouponLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (cardExpiryDateProps.ref.current) {
      cardExpiryDateProps.ref.current.style.borderRadius = "0.25rem";
    }
    if (cardCvcProps.ref.current) {
      cardCvcProps.ref.current.style.borderRadius = "0.25rem";
    }
  });



  const isDesktop = useIsDesktop();

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Row>
        <div className="pay-card-form">
          <div className="card-form-fields">
            {currentId === 2 && (
              <>
                <h4
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  className="mt-3"
                >
                  Datos De Facturación
                </h4>
                <hr style={{ marginTop: "27px" }} />
                <Col>
                  <FormGroup className="input-group">
                    <div className="position-relative w-100">
                      <label
                        className={`poppins-family ${
                          isDesktop ? "fw-bold" : ""
                        }`}
                      >
                        Nombre
                      </label>
                      <input
                        required={validateCoupon ===  false}
                        ref={nameUserRef}
                        className="form-control w-100 payment-method-input ps-5"
                        style={{ color: "#ECA109", fontWeight: "bold" }}
                      />
                    </div>
                    <small className="poppins-family ps-3">
                      {errors.first_name?.message}
                    </small>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="input-group">
                    <div className="position-relative w-100">
                      <label
                        className={`poppins-family ${
                          isDesktop ? "fw-bold" : ""
                        }`}
                      >
                        Apellidos
                      </label>
                      <input
                        required={validateCoupon ===  false}
                        ref={lastNameUserRef}
                        className="form-control w-100 payment-method-input ps-5"
                        style={{ color: "#ECA109", fontWeight: "bold" }}
                      />
                    </div>
                    <small className="poppins-family ps-3">
                      {errors.last_name?.message}
                    </small>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="input-group">
                    <div className="position-relative w-100">
                      <label
                        className={`poppins-family ${
                          isDesktop ? "fw-bold" : ""
                        }`}
                      >
                        Tipo de Identificación
                      </label>
                      <select
                        required={validateCoupon ===  false}
                        ref={idTypeRef}
                        className="form-select w-100 payment-method-input ps-5"
                        style={{ color: "#ECA109", fontWeight: "bold" }}
                      >
                        <option value="CC">Cédula Cuidadanía</option>
                        <option value="CE">Cédula Extrangera</option>
                        <option value="PP">Pasaporte</option>
                        <option value="NIT">
                          Número de Identificación Tributaria
                        </option>
                      </select>
                    </div>
                    {/* <small className="poppins-family ps-3">
                                {erroredInputs.cardNumber && erroredInputs.cardNumber}
                            </small> */}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="input-group">
                    <div className="position-relative w-100">
                      <label
                        className={`poppins-family  identication-label ${
                          isDesktop ? "fw-bold" : ""
                        }`}
                      >
                        Número de identificación
                      </label>
                      <input
                        required={validateCoupon ===  false}
                        type="number"
                        ref={idRef}
                        className="form-control w-100 payment-method-input ps-5"
                        style={{ color: "#ECA109", fontWeight: "bold" }}
                      />
                    </div>
                    <small className="poppins-family ps-3">
                      {errors.id?.message}
                    </small>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="input-group">
                    <div className="position-relative w-100">
                      <label
                        className={`poppins-family ${
                          isDesktop ? "fw-bold" : ""
                        }`}
                      >
                        Teléfono
                      </label>
                      <input
                        required={validateCoupon ===  false}
                        type="number"
                        ref={phoneRef}
                        defaultValue={currentUser.store.manager_phone}
                        className="form-control w-100 payment-method-input ps-5"
                        style={{ color: "#ECA109", fontWeight: "bold" }}
                      />
                    </div>
                    <small className="poppins-family ps-3">
                      {errors.cellphone?.message}
                    </small>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="input-group">
                    <div className="position-relative w-100">
                      <label
                        className={`poppins-family ${
                          isDesktop ? "fw-bold" : ""
                        }`}
                      >
                        Código Postal
                      </label>
                      <input
                        required={validateCoupon ===  false}
                        ref={postCodeRef}
                        className="form-control w-100 payment-method-input ps-5"
                        style={{ color: "#ECA109", fontWeight: "bold" }}
                      />
                    </div>
                    <small className="poppins-family ps-3">
                      {errors.last_name?.message}
                    </small>
                  </FormGroup>
                </Col>
                <Col className="email-field">
                  <FormGroup className="input-group">
                    <div className="position-relative w-100">
                      <label
                        className={`poppins-family ${
                          isDesktop ? "fw-bold" : ""
                        }`}
                      >
                        Correo Electrónico
                      </label>
                      <input
                        required={validateCoupon ===  false}
                        ref={emailRef}
                        defaultValue={currentUser.username}
                        className="form-control w-100 payment-method-input ps-5"
                        style={{ color: "#ECA109", fontWeight: "bold" }}
                      />
                    </div>
                    <small className="poppins-family ps-3">
                      {errors.email?.message}
                    </small>
                  </FormGroup>
                </Col>
              </>
            )}
            <h4
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "600",
              }}
              className="mt-3"
            >
              Datos De Tarjeta
            </h4>
            <hr style={{ marginTop: "27px" }} />
            <Col className="card-name-field">
              <FormGroup className="input-group">
                <div className="position-relative w-100">
                  <svg
                    {...getCardImageProps({ images })}
                    className="me-3 position-absolute"
                  />
                  <label
                    className={`poppins-family ${isDesktop ? "fw-bold" : ""}`}
                  >
                    Número de tarjeta
                  </label>
                  <input
                    {...cardNumberProps}
                    className="form-control w-100 payment-method-input ps-3"
                    required
                    style={{ color: "#ECA109", fontWeight: "bold" }}
                  />
                </div>
                <small className="poppins-family ps-3">
                  {erroredInputs.cardNumber && erroredInputs.cardNumber}
                </small>
              </FormGroup>
            </Col>
            <Col className="card-experitation-field">
              <FormGroup>
                <div className="input-group flex-column">
                  <label
                    className={`poppins-family ${isDesktop ? "fw-bold" : ""}`}
                  >
                    Expiración
                  </label>
                  <input
                    {...cardExpiryDateProps}
                    className="form-control w-100 payment-method-input ps-3 fw-bold"
                    maxLength={7}
                    minLength={7}
                    required={validateCoupon ===  false}
                  />
                  <small className="poppins-family ps-2">
                    {erroredInputs.expiryDate && erroredInputs.expiryDate}
                  </small>
                </div>
              </FormGroup>
            </Col>
            <Col className="card-ccv-field">
              <FormGroup>
                <div className="input-group flex-column">
                  <label
                    className={`poppins-family ${isDesktop ? "fw-bold" : ""}`}
                  >
                    CVV
                  </label>
                  <input
                    {...cardCvcProps}
                    className="form-control w-100 payment-method-input ps-3 fw-bold"
                    required
                    style={{ borderRadius: "0.25rem !important" }}
                  />
                  <small className="poppins-family ps-2">
                    {erroredInputs.cvc && erroredInputs.cvc}
                  </small>
                </div>
              </FormGroup>
            </Col>
            <Col className="card-full-name-field">
              <FormGroup>
                <label
                  htmlFor="cardnameInput"
                  className={`poppins-family ${isDesktop ? "fw-bold" : ""}`}
                >
                  Nombre del Titular de la Tarjeta
                </label>
                <input
                  type="text"
                  className="form-control w-100 payment-method-input ps-3 fw-bold"
                  placeholder="Nombre de la tarjeta"
                  ref={cardNameRef}
                  required
                />
              </FormGroup>
            </Col>
          </div>
          <div className="security-logos">
            <Col
              lg="4"
              xs={6}
              className={`d-flex align-items-center ${
                isDesktop ? "justify-content-start" : "justify-content-center"
              }`}
            >
              <div className={`plan-securely-ssl-wrapper`}>
                <figure className="lock-content">
                  <img src={lock} alt="" className="lock-item" />
                </figure>
                <h4 className="plan-securely">Secured with SSL</h4>
              </div>
            </Col>
            <Col xs={6}>
              <div className="card-logos">
                <img src={onvo} alt="" />
                <img src={visa} alt="" />
              </div>
            </Col>
          </div>
          <hr />
          <Row
            className={`bg-white p-md-4 ${isDesktop ? "mt-4" : ""}`}
            style={isDesktop ? { borderRadius: "2.5rem" } : {}}
          >
            <Col lg="12" xs={12}>
              <div className="input-group flex-column">
                <Row>
                  <Col className="coupon-header">
                    <label className="poppins-family fw-bold">
                      Tengo un código de promoción
                    </label>

                    <button
                      type="button"
                      onClick={() => setOpen(!open)}
                      className={`${open ? "dropdown-btn" : "dropdown-close"}`}
                    >
                        <img src={dropDown} alt="dropdown icon" />
                    </button>
                  </Col>
                </Row>
                {
                  open && (
                    <div
                  className={`coupon-controls-control ${
                    isDesktop ? "" : "d-flex align-items-center"
                  } ${open ? "show-coupon" : "hide-coupon"}`}
                >
                  <input
                    className={`coupon-input form-control payment-method-input fw-bold ${
                      isDesktop ? "ps-5" : "ps-3"
                    }`}
                    placeholder="Escribe tu código aquí"
                    ref={couponRef}
                    style={{ borderRadius: "0.25rem !important" }}
                  />

                  <Button
                    className="cupon-code-btn"
                    size="lg"
                    onClick={applyCupon}
                    disabled={false}
                  >
                    {couponLoading ? <LoadingSpinner /> : "Aplicar"}
                  </Button>
                </div>
                  )
                }
                <Row>
                  <Col sm={12} className="mt-2">
                    {!!couponMessage && (
                      <span
                        className="span-free-membership fw-bold"
                        style={validateCoupon ? {} : { color: "#FF8264" }}
                      >
                        {couponMessage}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Row>
              <Col xs={8} lg={5} className="mt-3">
                <div className="d-flex flex-column">
                  <label className="label-price-details fw-bold">
                    Subtotal
                  </label>
                  <label className="label-price-details fw-bold mt-3">
                    Total
                  </label>
                </div>
              </Col>
              <Col xs={4} lg={7} className="mt-3">
                <div className="d-flex flex-column">
                  <label className="label-price-details fw-bold">
                    {`$${
                      couponType
                        ? couponType === 1
                          ? "0.00"
                          : couponType === 2
                          ? "9.99"
                          : couponType === 3
                          ? "54.00"
                          : planId == 30 && couponType === 5
                          ? "00.00"
                          : couponType === 5 &&
                            validateCoupon.coupon_type_name === "BEEBASICO"
                          ? "00.00"
                          : couponType === 5 &&
                            validateCoupon.coupon_type_name === "BEE50"
                          ? intl.format(showPrice)
                          : intl.format(basePrice)
                        : discount
                        ? intl.format(discount) 
                        :intl.format(basePrice)
                    }`}
                  </label>
                  <label className="label-price-details fw-bold mt-3">
                    {`$${
                      couponType
                        ? couponType === 1
                          ? "0.00"
                          : couponType === 2
                          ? "9.99"
                          : couponType === 3
                          ? "54.00"
                          : planId == 30 && couponType === 5
                          ? "00.00"
                          : couponType === 5 &&
                            validateCoupon.coupon_type_name === "BEEBASICO"
                          ? "00.00"
                          : couponType === 5 &&
                            validateCoupon.coupon_type_name === "BEE50"
                          ? intl.format(showPrice)
                          : intl.format(basePrice)
                        : discount
                        ? intl.format(discount) 
                        :intl.format(basePrice)
                    }`}
                  </label>
                </div>
              </Col>
            </Row>
            <Col sm="12" className="mt-4">
              <Row>
                <Col className="payment-btn-container">
                  <button disabled={loading} className="payment-form-btn">
                    {loading && <LoadingSpinner />}
                    EMPEZAR YA <i className="fas fa-arrow-right" />
                  </button>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <small
                    className="text-danger mt-1"
                    style={{ fontSize: "16px" }}
                  >
                    {dataError && "*Verifique los datos del formulario"}
                  </small>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Row>
    </form>
  );
};
