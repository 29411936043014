import React, { useState, useEffect } from "react";
import { Button, Input, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import alertCircle from "../../assets/images/logo/alert-circle-outline.png";
import "../../assets/scss/custom/pages/_prizesetup-one.scss";
import { PrizeRepository } from "../../repositories/prize";
import { PrizeOptionsRepository } from "../../repositories/prizeOptions";
import { numberWithCommas } from "../../helpers/funtions";
import { useCheckPaymentMethod } from "../../app/hook/useCheckPaymentMethod";
import { Link } from "react-router-dom/cjs/react-router-dom";
import PrizeRightSide from "../../components/PrizeSetUp";
import PrizeCard from "../../components/PrizeCard";
import beeLogo from "../../assets/images/LOGO.png";
import { useScreenHeight } from "../../helpers/useScreenHeight";
import { useIsDesktop } from "../../helpers/useIsDesktop";
import { ContainerBottom } from "../Helpers/ContainerBottom";

let MAX_SETUP = 3;
let CONFIG_STEP = [];
let prize;

const GenericSealSetUp = (props) => {
  ////useCheckPaymentMethod();
  const { history } = props,
    params = useParams(),
    pageNumber = Number(params.pageNumber) || 1,
    [newOption, setNewOption] = useState(""),
    [configStep, setConfigStep] = useState({}),
    [options, setOptions] = useState([]),
    [checkedOptions, setCheckedOptions] = useState([]),
    [checkedInitialOptions, setCheckedInitialOptions] = useState([]);
  const screenHeight = useScreenHeight();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    setCheckedOptions([]);
    setCheckedInitialOptions([]);

    const repository = new PrizeRepository();
    const optionsRepository = new PrizeOptionsRepository();
    repository
      .listByStoreCategoryIdAndCountryID()
      .then((data) => {
        MAX_SETUP = data.length;

        CONFIG_STEP = data.map((item) => ({
          id: item.id,
          amount: item.country_id.currency + numberWithCommas(item.amount),
          name: item.name,
          prize_number: Number(item.prize_number),
          example: item.example
        }));

        if (CONFIG_STEP.length > pageNumber - 1) {
          let _prize = CONFIG_STEP.filter(
            (item) => item.prize_number === pageNumber
          );

          _prize = _prize.length ? _prize[0] : CONFIG_STEP[pageNumber - 1];
          prize = _prize;
          prize.id = Number(prize.id);
          setConfigStep(_prize);
          optionsRepository
            .listPrizeIdOrStoreId(_prize.id)
            .then((options) => {
              let checked = options
                .filter((item) => item.checked)
                .map((item) => item.id);

              setCheckedOptions([...checked]);
              setCheckedInitialOptions([...checked]);
              setOptions(
                options.map((option) => ({
                  id: option.id,
                  key: option.name,
                  checked: option.checked || false
                }))
              );
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, [pageNumber]);

  const handleCheckBoxAdd = () => {
    if (newOption.length) {
      const optionsRepository = new PrizeOptionsRepository();
      optionsRepository
        .registerCustomOption({
          name: newOption,
          prize_id: prize.id
        })
        .then((data) => {
          let newOpt = {
            id: data.id,
            key: newOption,
            checked: true
          };
          setOptions([...options, newOpt]);
          setNewOption("");
          setCheckedOptions([...checkedOptions, newOpt.id]);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChecked = (checked, item) => {
    if (checked) {
      setCheckedOptions([...checkedOptions, item.id]);
    } else {
      setCheckedOptions(checkedOptions.filter((it) => it !== item.id));
    }
    setOptions(
      options.map((i) => {
        return i.id === item.id ? { ...item, checked: !i.checked } : i;
      })
    );
  };

  const handleNextRoute = () => {
    if (
      checkedOptions.length !== checkedInitialOptions.length ||
      checkedOptions.filter((item) => !checkedInitialOptions.includes(item))
        .length > 0
    ) {
      const optionsRepository = new PrizeOptionsRepository();
      optionsRepository
        .registerSelectedOptions({
          prize_id: prize.id,
          options: checkedOptions
        })
        .then((_) => {
          pageNumber === MAX_SETUP
            ? history.push("/wlcm-two")
            : history.push(`/setup-generic-prize/${pageNumber + 1}`);
        })
        .catch((error) => console.log(error));
    } else {
      pageNumber === MAX_SETUP
        ? history.push("/wlcm-two")
        : history.push(`/setup-generic-prize/${pageNumber + 1}`);
    }
  };

  //const getTitleStep = (step) => titleMap[step];

  return (
    <>
      {isDesktop && (
        <div
          className="container-prize"
          style={{ height: screenHeight, minHeight: screenHeight }}
        >
          <Row className="align-items-center">
            <Col lg={6}>
              <div
                className="left-side"
                style={{ height: screenHeight, minHeight: screenHeight }}
              >
                <Row>
                  <Col lg={{ offset: 1 }} className="mt-5">
                    <img src={beeLogo} alt="Logo Bee" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={{ offset: 1 }} className="mt-2">
                    <span className="ms-2 subtitle">Premio #{pageNumber}</span>
                    <p className="description">
                      Selecciona los premios que estarías dispuesto a darle a un
                      <br />
                      cliente que ha consumido {configStep.amount} en tu
                      restaurante:
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={{ offset: 1, size: 10 }}>
                    <Row className="flex-wrap gap-1">
                      {options.map((option) => {
                        return (
                          <Col
                            key={option.id}
                            className="m-0 p-0"
                            md={0}
                            lg={0}
                            xl={0}
                          >
                            <div style={{ width: "max-content"}} className="checkbox-listing p-2 ps-3 item-prize gap-2 d-flex align-items-center">
                              <Input
                                type="checkbox"
                                className="pt-0 mt-0 rounded-circle"
                                id={option.id}
                                name={option.id}
                                checked={option.checked}
                                disabled={
                                  !checkedOptions.includes(option.id) &&
                                  checkedOptions.length > 2
                                }
                                onChange={(event) =>
                                  handleChecked(event.target.checked, option)
                                }
                              />{" "}
                              <label className="pb-0 pt-0 mb-0" htmlFor={option.id}>
                                {option.key.length > 20
                                  ? `${option.key.slice(0, 25)}...`
                                  : option.key}
                              </label>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={{ offset: 1, size: 10 }}>
                    <div>
                      <p
                        className="description fw-bold"
                        style={{ fontSize: "18px" }}
                      >
                        ¿Quieres dar algo que no esta entre las opciones?
                        <br />
                        Agrega tu propio premio
                      </p>
                    </div>
                  </Col>
                  <Col lg={{ offset: 1, size: 4 }}>
                    <Input
                      type="text"
                      name="addOption"
                      placeholder="Nombre del Premio"
                      className="input-add-options ps-3 py-2"
                      value={newOption}
                      onChange={(event) => setNewOption(event.target.value)}
                      disabled={!(checkedOptions.length < 3)}
                    />
                  </Col>
                  <Col lg={3}>
                    <Button
                      disabled={!(checkedOptions.length < 3)}
                      onClick={handleCheckBoxAdd}
                      className="add-button"
                    >
                      Agregar +
                    </Button>
                  </Col>
                  <Col lg={{ offset: 1, size: 10 }} className="mt-3">
                    <span className="award-content-span description">
                      Por ejemplo: {configStep.example}
                    </span>
                    <div className="">
                      <p className="info pt-3">
                        <img src={alertCircle} alt="alert-circle" /> Debes
                        seleccionar mínimo 1 opción y máximo 3 opciones
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="fixed-bottom mb-5">
                  <Col sm="3" lg={{ offset: 1, size: 3 }}>
                    <Link to="#" onClick={() => window.history.back()}>
                      <button
                        type="button"
                        className="register-back-button mt-3"
                      >
                        Regresar
                      </button>
                    </Link>
                  </Col>
                  <Col sm="3" lg="3" className="">
                    <Button
                      onClick={handleNextRoute}
                      disabled={!(checkedOptions.length >= 1)}
                      className="button-generic-prize px-5"
                    >
                      Continuar
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6}>
              <PrizeRightSide
                title={`¡Construye un plan de\nlealtad a medida!`}
                step={2}
                nextButton={false}
              />
              <PrizeCard category={pageNumber} />
            </Col>
          </Row>
        </div>
      )}
      {!isDesktop && (
        <div
          style={{
            height: screenHeight,
            overflowX: "hidden"
          }}
          className="container-prize"
        >
          <Row>
            <Col xs={12}>
              <p className="title text-center pt-4">
                Construye tu plan de
                <br /> lealtad a medida
              </p>
              <p className="title text-center" style={{ color: "#999999" }}>
                Premio #{pageNumber}
              </p>
            </Col>
            <Col xs={12} className="">
              <PrizeCard category={pageNumber} />
            </Col>
          </Row>

          <ContainerBottom height={screenHeight * 0.45}>
            <Row>
              <Col xs={12} className="d-flex justify-content-center mt-3">
                <span className="text-center" style={{ fontSize: "16px" }}>
                  Elige premios para clientes que han consumido
                  <br />
                  {configStep.amount} en tu negocio:
                </span>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                className="mx-2 mt-2 px-2"
                style={{ width: window.innerWidth }}
              >
                <Row>
                  {options.map((option) => {
                    return (
                      <Col key={option.id} className="mt-1">
                        <div
                          style={{ width: "max-content"}}
                          className={`d-flex justify-content-center item-prize ${
                            option.checked ? "option-checked" : ""
                          }`}
                        >
                          <Input
                            type="checkbox"
                            className=""
                            id={option.id}
                            name={option.id}
                            checked={option.checked}
                            disabled={
                              !checkedOptions.includes(option.id) &&
                              checkedOptions.length > 2
                            }
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleChecked(event.target.checked, option)
                            }
                          />
                          <label htmlFor={option.id} className="pt-2">
                            {option.key.length > 15
                              ? `${option.key.slice(0, 15)}...`
                              : option.key}
                          </label>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12}>
                <div className="px-1">
                  <p className="description text-center fw-bold">
                    ¿Quieres dar algo que no esta entre las opciones?
                    <br />
                    Agrega tu propio premio
                  </p>
                </div>
              </Col>
              <Col xs={8} className="px-4">
                <Input
                  type="text"
                  name="addOption"
                  placeholder="Nombre del Premio"
                  className="input-add-options ps-3 py-2"
                  value={newOption}
                  onChange={(event) => setNewOption(event.target.value)}
                  disabled={!(checkedOptions.length < 3)}
                />
              </Col>
              <Col xs={4}>
                <Button
                  disabled={!(checkedOptions.length < 3)}
                  onClick={handleCheckBoxAdd}
                  className="add-button btn btn-lg"
                >
                  Agregar +
                </Button>
              </Col>
              <Col xs={12} className="px-4 mt-2">
                <span
                  className="award-content-span"
                  style={{ fontSize: "8px", color: "#999" }}
                >
                  Por ejemplo: {configStep.example}
                </span>
                <div className="">
                  <p
                    className="pt-1"
                    style={{ fontSize: "8px", color: "#999" }}
                  >
                    <img
                      src={alertCircle}
                      alt="alert-circle"
                      style={{ transform: "scale(0.5)" }}
                    />
                    Debes seleccionar mínimo 1 opción y máximo 3 opciones
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col
                xs={12}
                className="m-0 p-0 d-flex justify-content-center mt-3"
              >
                <div className="line_button mx-2">
                  <Button
                    onClick={handleNextRoute}
                    className="button-continue btn btn-lg px-5"
                    disabled={!(checkedOptions.length >= 1)}
                  >
                    CONTINUAR
                    <i className="fa-solid fa-arrow-right text-white ms-2"></i>
                  </Button>
                </div>
              </Col>
              <Col xs={12}>
                <Link to="#" onClick={() => window.history.back()}>
                  <button type="button" className="register-back-button mt-3">
                    Regresar
                  </button>
                </Link>
              </Col>
            </Row>
          </ContainerBottom>
        </div>
      )}
    </>
  );
};

export default GenericSealSetUp;
