import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, CardBody, Card } from "reactstrap";
import Xarrow from "react-xarrows";
import { StoreRepository } from "../../repositories/store";
import bronze from "../../assets/images/prize-bronze.svg";
import silver from "../../assets/images/prize-silver.svg";
import gold from "../../assets/images/prize-gold.svg";
import "./SealSetUp.css";
import { GENERIC_CATEGORY } from "../../core/constants/store";
import { useHistory } from "react-router-dom";

//import "../../assets/scss/custom/pages/_profile-details.scss";
import { useCheckPaymentMethod } from "../../app/hook/useCheckPaymentMethod";
import { useDispatch, useSelector } from "react-redux";
import { loadStore } from "../../app/store/slices/storeSlice";
import beeLogo from "../../assets/images/LOGO@3x.png";
import "./index.css";
import { useIsDesktop } from "../../helpers/useIsDesktop";
import { useScreenHeight } from "../../helpers/useScreenHeight";
import { ContainerBottom } from "../Helpers/ContainerBottom";
import { Link } from "react-router-dom";

const SealCard = ({ border = true, children }) => {
  const isDesktop = useIsDesktop();


  return (
    <Card
      className="card-rounded"
      style={{
        border: border ? "2px solid #ccc" : "none",
        height: isDesktop ? "4.5rem" : "2.5rem",
        width: isDesktop ? "4.5rem" : "2.5rem",
        overflow: "hidden"
      }}
    >
      <CardBody
        className={`${
          !isDesktop
            ? "d-flex justify-content-center align-items-center py-0 px-0"
            : ""
        }`}
      >
        {children}
      </CardBody>
    </Card>
  );
};

const SealSetUp = () => {
  //useCheckPaymentMethod();
  const [amount, setAmount] = useState("");
  const [rawValue, setRawValue] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.store.store);
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const heightScreen = useScreenHeight();
  const intl = new Intl.NumberFormat();

  const [inputWidth, setInputWidth] = useState("10rem");

  const adjustInputWidth = (value) => {
    const tempSpan = document.createElement("span");
    tempSpan.style.visibility = "hidden";
    tempSpan.style.position = "absolute";
    tempSpan.style.fontSize = "64px"; // Coincide con el tamaño del input
    tempSpan.style.whiteSpace = "nowrap";
    tempSpan.textContent = value || "10,000.00"; // Ejemplo base
    document.body.appendChild(tempSpan);

    const newWidth = Math.max(tempSpan.offsetWidth + 20, 80) + "px"; // Ancho mínimo
    setInputWidth(newWidth);
    document.body.removeChild(tempSpan);
  };

  const formatCurrency = (value, addDecimals = false) => {
    if (!value) return ""; // Manejar valores vacíos
    const number = parseFloat(value.toString().replace(/,/g, "")); // Convertir a número
    if (isNaN(number)) return ""; // Evitar errores si no es número

    const options = {
      minimumFractionDigits: addDecimals ? 2 : 0, // Mostrar decimales solo si se necesita
      maximumFractionDigits: 2,
    };

    // Usar el formato estándar (comas para miles y puntos para decimales)
    return new Intl.NumberFormat("en-US", options).format(number);
  };
  
  

  useEffect(() => {
    new StoreRepository()
      .getStore()
      .then((data) => {
        if (data.store_category_id.name !== GENERIC_CATEGORY) {
          history.push("/prize-info");
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    dispatch(loadStore());
  }, [dispatch]);

  const handleValueChange = (e) => {
    const input = e.target.value.replace(/,/g, "").replace(/\./g, ""); // Eliminar separadores existentes

    // Solo permitir números
    const re = /^[0-9\b]+$/; // Números y retroceso
    if (input === "" || re.test(input)) {
      setRawValue(input); // Actualizar el valor crudo
      adjustInputWidth(formatCurrency(input)); // Ajustar el ancho basado en el formateo
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const getAmount = (times) => {
    if (!!rawValue) {
      return intl.format(parseInt(rawValue) * times);
    }
    return `x${times}`;
  };

  const getTotalAmount = () => {
    return parseInt(rawValue) * 10;
  };

  const handleClick = async () => {
    if (!!rawValue) {
      const repository = new StoreRepository();
      const response = await repository.setCardAmount(getTotalAmount());
      if (response.status !== 200 && response.status !== 201) {
        console.error();
      } else {
        history.push("/setup-generic-prize/1");
      }
    }
  };


  return (
    <>
      {isDesktop && (
        <div className="profile-details-seal m-0 p-0">
          <Row className="">
            <Col md={0} lg={{ offset: 1, size: 10 }}>
              <div className="mt-5">
                <img
                  src={beeLogo}
                  alt=""
                  className="img-fluid"
                  width="150"
                  height="50"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              sm="12"
              lg={{ size: 10, offset: 1 }}
              className="d-flex justify-content-center mt-1"
            >
              <h1 className="text-center title">
                Define el monto para rellenar un sello
              </h1>
            </Col>
            <Col sm="12">
              <div className="p-0 m-0">
                <p
                  className="d-flex justify-content-center m-0 p-0"
                  style={{
                    color: "#575757",
                    fontSize: "18px",
                    fontFamily: "'Avenir'",
                  }}
                >
                  El monto debería ser del promedio que te consume un cliente en
                  c/visita
                </p>
                <p
                  className="d-flex justify-content-center m-0 p-0"
                  style={{
                    color: "#575757",
                    fontSize: "18px",
                    fontFamily: "'Avenir'",
                    marginTop: "0.25rem",
                  }}
                >
                  En
                  <span style={{ fontWeight: "bold" }} className="ms-1">
                    {data?.category_description}
                  </span>
                  , recomendamos:{" "}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="d-flex justify-content-center">
              <div className="text-center mt-3">
                <div
                  className="input-amount d-flex justify-content-center align-items-center"
                  style={{ width: "auto" }} // Permitir ancho automático
                >
                  <span
                    className="input-group-text seal-suffix"
                    style={{ fontSize: "48px", marginRight: "0.5rem" }}
                  >
                    {data?.country_id?.currency}
                  </span>
                  <input
                    type="text"
                    placeholder={"10,000.00"}
                    value={formatCurrency(rawValue)} // Mostrar el valor formateado
                    onChange={handleValueChange} // Actualizar el valor crudo
                    className="form-control seal-input"
                    style={{ width: inputWidth }} // Ajustar el ancho dinámicamente
                  />
                </div>
              </div>
            </Col>
            <Col lg={12} className="d-flex justify-content-center">
              <div className="mt-1">
                <span>Digita el monto por cada sello</span>
              </div>
            </Col>
          </Row>
          <div className="personalize-profile-section ">
            <div className="text-center">
              <div className="mt-4">
                <Row>
                  <Col
                    sm="12"
                    lg={{ size: 2, offset: 1 }}
                    className="level-bronce"
                  >
                    <div>
                      <span
                        id="level-bronce"
                        style={{
                          fontSize: "32px",
                          fontWeight: "bold",
                          fontFamily: "'Avenir'",
                        }}
                      >
                        Primer Premio
                      </span>
                      <h5 style={{ color: "#8b24b6", fontSize: "20px" }}>
                        Bronce
                      </h5>
                      <div className="px-3">
                        <Card className="card-rounded card-bronce">
                          <CardBody>
                            <div className="text-white ">
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "'Avenir'",
                                }}
                                className="text-white"
                              >
                                {data?.country_id?.currency}
                              </span>{" "}
                              <span
                                className="text-white"
                                style={{
                                  fontSize: "24px",
                                  fontFamily: "'Avenir'",
                                  fontWeight: "bold",
                                }}
                              >
                                {getAmount(1)}
                              </span>
                            </div>
                            <p
                              className="text-white"
                              style={{ fontSize: "16px" }}
                            >
                              Deben consumir para obtener este premio
                            </p>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Col>
                  <Col
                    sm="12"
                    lg={{ size: 4, offset: 1 }}
                    className="card-prize__container"
                  >
                    <Card className="card-rounded card-shadow">
                      <CardBody className="card-prize__body">
                        <div>
                          <h2
                            style={{
                              color: "#B2B2B2",
                              fontFamily: "'Avenir'",
                            }}
                          >
                            TARJETA DE TU CLIENTE
                          </h2>
                        </div>
                        <Row className="card-prize__items">
                          <Col sm={{ offset: 1, size: 2 }}>
                            <SealCard border={false}>
                              <div
                                className="text-center card-bronce"
                                style={{
                                  width: "4.5rem",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <img
                                  id="bronze"
                                  src={bronze}
                                  style={{
                                    width: "100%",
                                  }}
                                  className="img-fluid ps-1 pt-1"
                                  alt=""
                                />
                              </div>
                            </SealCard>
                          </Col>
                          <Col sm="2">
                            <SealCard>
                              <b
                                style={{
                                  color: "#B2B2B2",
                                  fontSize: "1.2em",
                                }}
                              >
                                2
                              </b>
                            </SealCard>
                          </Col>
                          <Col sm="2">
                            <SealCard>
                              <b
                                style={{
                                  color: "#B2B2B2",
                                  fontSize: "1.2em",
                                }}
                              >
                                3
                              </b>
                            </SealCard>
                          </Col>
                          <Col sm="2">
                            <SealCard border={false}>
                              <div
                                className="text-center card-silver"
                                style={{
                                  width: "4.5rem",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <img
                                  id="silver"
                                  src={silver}
                                  style={{
                                    width: "100%",
                                  }}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </SealCard>
                          </Col>
                          <Col sm="2">
                            <SealCard>
                              <b
                                style={{
                                  color: "#B2B2B2",
                                  fontSize: "1.2em",
                                }}
                              >
                                5
                              </b>
                            </SealCard>
                          </Col>
                          <Col sm={{ offset: 1, size: 2 }}>
                            <SealCard>
                              <b
                                style={{
                                  color: "#B2B2B2",
                                  fontSize: "1.2em",
                                }}
                              >
                                6
                              </b>
                            </SealCard>
                          </Col>
                          <Col sm="2">
                            <SealCard>
                              <b
                                style={{
                                  color: "#B2B2B2",
                                  fontSize: "1.2em",
                                }}
                              >
                                7
                              </b>
                            </SealCard>
                          </Col>
                          <Col sm="2">
                            <SealCard>
                              <b
                                style={{
                                  color: "#B2B2B2",
                                  fontSize: "1.2em",
                                }}
                              >
                                8
                              </b>
                            </SealCard>
                          </Col>
                          <Col sm="2">
                            <SealCard>
                              <b
                                style={{
                                  color: "#B2B2B2",
                                  fontSize: "1.2em",
                                }}
                              >
                                9
                              </b>
                            </SealCard>
                          </Col>
                          <Col sm="2">
                            <SealCard border={false}>
                              <div
                                className="text-center card-gold"
                                style={{
                                  width: "4.5rem",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <img
                                  id="gold"
                                  src={gold}
                                  style={{
                                    width: "100%",
                                  }}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </SealCard>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Xarrow
                      start="level-bronce"
                      end="bronze"
                      lineColor="#8525b7"
                      color="#8525b7"
                      dashness
                      path="grid"
                      tailShape="circle"
                      showTail
                      tailSize={3}
                      showHead={false}
                    />
                    <Xarrow
                      start="level-silver"
                      end="silver"
                      lineColor="#f6007b"
                      color="#f6007b"
                      dashness
                      path="grid"
                      tailShape="circle"
                      showTail
                      tailSize={3}
                      showHead={false}
                      className="level-silver"
                    />
                    <Xarrow
                      start="level-gold"
                      end="gold"
                      dashness
                      path="grid"
                      color="#DEA01A"
                      lineColor="#DEA01A"
                      tailShape="circle"
                      showTail
                      tailSize={3}
                      showHead={false}
                    />
                  </Col>
                  <Col
                    sm="12"
                    lg={{ size: 2, offset: 1 }}
                    className="silver-card__item"
                  >
                    <Row>
                      <Col sm="12" lg={{ size: 11 }}>
                        <span
                          id="level-silver"
                          style={{
                            fontSize: "32px",
                            fontWeight: "bold",
                            fontFamily: "'Avenir'",
                          }}
                        >
                          Segundo Premio
                        </span>
                        <h5 style={{ color: "#FF0080", fontSize: "20px" }}>
                          Platinum
                        </h5>
                        <div>
                          <Card className="card-rounded card-silver">
                            <CardBody>
                              <div className="text-white">
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "'Avenir'",
                                  }}
                                  className="text-white"
                                >
                                  {data?.country_id?.currency}
                                </span>{" "}
                                <span
                                  className="text-white"
                                  style={{
                                    fontSize: "24px",
                                    fontFamily: "'Avenir'",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {getAmount(4)}
                                </span>
                              </div>
                              <p
                                className="text-white"
                                style={{ fontSize: "16px" }}
                              >
                                Deben consumir para obtener este premio
                              </p>
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" lg={{ offset: 1, size: 11 }}>
                        <div className="ml-5">
                          <span
                            id="level-gold"
                            style={{
                              fontSize: "32px",
                              fontWeight: "bold",
                              fontFamily: "'Avenir'",
                            }}
                          >
                            Tercer Premio
                          </span>
                          <h5 style={{ color: "#ECA109", fontSize: "20px" }}>
                            Gold
                          </h5>
                          <Card className="card-rounded card-gold">
                            <CardBody>
                              <div className="text-white">
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "'Avenir'",
                                  }}
                                  className="text-white"
                                >
                                  {data?.country_id?.currency}
                                </span>{" "}
                                <span
                                  className="text-white"
                                  style={{
                                    fontSize: "24px",
                                    fontFamily: "'Avenir'",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {getAmount(10)}
                                </span>
                              </div>
                              <p
                                className="text-white"
                                style={{ fontSize: "16px" }}
                              >
                                Deben consumir para obtener este premio
                              </p>
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="personalize-section pb-5">
              <div className="line_button_section">
                <Row className="align-items-center">
                  <Col
                    className="m-0 p-0 d-flex justify-content-center"
                    md={0}
                    lg={12}
                    xl={0}
                  >
                    <div className="line_button mx-2">
                      <Button
                        onClick={handleClick}
                        className="button-continue"
                        disabled={!!!rawValue}
                      >
                        CONTINUAR
                        <i className="fa-solid fa-arrow-right text-white ms-2"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isDesktop && (
        <div className="profile-details-seal">
          <div>
            <Col xs="12" className="d-flex justify-content-center pt-5">
              <h1 className="text-center title">
                Define el monto para
                <br />
                rellenar un sello
              </h1>
            </Col>
          </div>
          <div>
            <Col xs="12" className="d-flex justify-content-center mt-1">
              <div className="p-0 m-0">
                <p
                  className="d-flex justify-content-center px-5 text-center"
                  style={{
                    color: "#999999",
                    fontSize: "12px",
                    fontFamily: "'Poppins'",
                  }}
                >
                  El monto debería ser del promedio que te consume un cliente en
                  c/visita
                </p>
                <p
                  className="d-flex justify-content-center text-center"
                  style={{
                    color: "#999999",
                    fontSize: "12px",
                    fontFamily: "'Poppins'",
                    marginTop: "0.25rem",
                  }}
                >
                  En
                  <span style={{ fontWeight: "bold" }} className="ms-1">
                    {data?.category_description}
                  </span>
                  , recomendamos:{" "}
                </p>
              </div>
            </Col>
          </div>
          <ContainerBottom>
            <div>
              <Col xs={12} className="d-flex justify-content-center mt-3">
              <div className="text-center mt-3">
                <div
                  className="input-amount d-flex justify-content-center align-items-center"
                  style={{ width: "auto" }} // Permitir ancho automático
                >
                  <span
                    className="input-group-text seal-suffix"
                    style={{ fontSize: "24px", marginRight: "0.5rem" }}
                  >
                    {data?.country_id?.currency}
                  </span>
                  <input
                    type="text"
                    placeholder={"10,000.00"}
                    value={formatCurrency(rawValue)} // Mostrar el valor formateado
                    onChange={handleValueChange} // Actualizar el valor crudo
                    className="form-control seal-input"
                    style={{ width: inputWidth }} // Ajustar el ancho dinámicamente
                  />
                </div>
              </div>
              </Col>
              <Col xs={12}>
                <div className="w-100 text-center mt-2">
                  <span className="text-center span-description-amount">
                    Digita el monto por cada sello
                  </span>
                </div>
              </Col>
            </div>
            <div>
              <Col xs="12" className="mt-5">
                <Card className="mx-3 card-rounded card-shadow">
                  <CardBody className="card-prize__body">
                    <div className="text-center">
                      <h2
                        style={{
                          color: "#B2B2B2",
                          fontFamily: "'Avenir'",
                        }}
                      >
                        TARJETA DE TU CLIENTE
                      </h2>
                    </div>
                    <Row className="card-prize__items">
                      <Col xs={{ offset: 1, size: 2 }}>
                        <SealCard border={false}>
                          <div
                            className="text-center card-bronce"
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                            }}
                          >
                            <img
                              id="bronze"
                              src={bronze}
                              style={{
                                width: "100%",
                              }}
                              className="img-fluid ps-1 pt-1"
                              alt=""
                            />
                          </div>
                        </SealCard>
                      </Col>
                      <Col xs="2">
                        <SealCard>
                          <b
                            style={{
                              color: "#B2B2B2",
                              fontSize: "1.2em",
                            }}
                          >
                            2
                          </b>
                        </SealCard>
                      </Col>
                      <Col xs="2">
                        <SealCard>
                          <b
                            style={{
                              color: "#B2B2B2",
                              fontSize: "1.2em",
                            }}
                          >
                            3
                          </b>
                        </SealCard>
                      </Col>
                      <Col xs="2">
                        <SealCard border={false}>
                          <div
                            className="text-center card-silver"
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                            }}
                          >
                            <img
                              id="silver"
                              src={silver}
                              style={{
                                width: "100%",
                              }}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </SealCard>
                      </Col>
                      <Col xs="2">
                        <SealCard>
                          <b
                            style={{
                              color: "#B2B2B2",
                              fontSize: "1.2em",
                            }}
                          >
                            5
                          </b>
                        </SealCard>
                      </Col>
                      <Col xs={{ offset: 1, size: 2 }}>
                        <SealCard>
                          <b
                            style={{
                              color: "#B2B2B2",
                              fontSize: "1.2em",
                            }}
                          >
                            6
                          </b>
                        </SealCard>
                      </Col>
                      <Col xs="2">
                        <SealCard>
                          <b
                            style={{
                              color: "#B2B2B2",
                              fontSize: "1.2em",
                            }}
                          >
                            7
                          </b>
                        </SealCard>
                      </Col>
                      <Col xs="2">
                        <SealCard>
                          <b
                            style={{
                              color: "#B2B2B2",
                              fontSize: "1.2em",
                            }}
                          >
                            8
                          </b>
                        </SealCard>
                      </Col>
                      <Col xs="2">
                        <SealCard>
                          <b
                            style={{
                              color: "#B2B2B2",
                              fontSize: "1.2em",
                            }}
                          >
                            9
                          </b>
                        </SealCard>
                      </Col>
                      <Col xs="2">
                        <SealCard border={false}>
                          <div
                            className="text-center card-gold"
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                            }}
                          >
                            <img
                              id="gold"
                              src={gold}
                              style={{
                                width: "100%",
                              }}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </SealCard>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div>
              <Col xs={12}>
                <div className="w-100 text-center">
                  <span className="text-center span-description-over-card">
                    Lo que deben consumir tus clientes para obtener cada premio:
                  </span>
                </div>
              </Col>
              <Col xs={12}>
                <div className="w-100 text-center mt-2">
                  <p className="prize-text-amount">
                    Primer Premio{" "}
                    <span style={{ color: "#8525b7" }}>Bronce</span>:{" "}
                    <u>
                      {data?.country_id?.currency} {getAmount(1)}
                    </u>
                  </p>
                  <p className="prize-text-amount">
                    Segundo Premio{" "}
                    <span style={{ color: "#F7047D" }}>Platinum</span>:{" "}
                    <u>
                      {data?.country_id?.currency} {getAmount(4)}
                    </u>
                  </p>
                  <p className="prize-text-amount">
                    Tercer Premio <span style={{ color: "#EC9F09" }}>Gold</span>
                    :{" "}
                    <u>
                      {data?.country_id?.currency} {getAmount(10)}
                    </u>
                  </p>
                </div>
              </Col>
            </div>
            <div className="align-items-center">
              <Col className="m-0 p-0 d-flex justify-content-center" xs={12}>
                <div className="line_button mx-2">
                  <Button
                    onClick={handleClick}
                    className="button-continue btn btn-lg px-5"
                    disabled={!!!rawValue}
                  >
                    CONTINUAR
                    <i className="fa-solid fa-arrow-right text-white ms-2"></i>
                  </Button>
                </div>
              </Col>
              <div>
                <Link to="#" onClick={() => window.history.back()}>
                  <button type="button" className="register-back-button mt-3">
                    Regresar
                  </button>
                </Link>
              </div>
            </div>
          </ContainerBottom>
        </div>
      )}
    </>
  );
};

SealSetUp.propTypes = {
  history: PropTypes.object
};

export default SealSetUp;
