import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ProfileSidePanel } from "../Helpers/ProfileSidePanel";
import { Row, Col, Button } from "reactstrap";
import { StoreRepository } from "../../repositories/store";

import BeeMockUp from "../../assets/images/layouts/bee-mock-up.png";

import "../../assets/scss/custom/pages/_profile-details.scss";
import { GENERIC_CATEGORY } from "../../core/constants/store";
import { useCheckPaymentMethod } from "../../app/hook/useCheckPaymentMethod";
import "./index.css";
import PrizeRightSide from "../../components/PrizeSetUp";
import { useIsDesktop } from "../../helpers/useIsDesktop";
import { useScreenHeight } from "../../helpers/useScreenHeight";

const ProfileDetails = (props) => {
  //useCheckPaymentMethod();

  const [logo, setLogo] = useState(null);
  const { history } = props;
  const [urlLogo, setUrlLogo] = useState(null);
  const [store, setStore] = useState(null);
  const isDesktop = useIsDesktop();
  const screenHeight = useScreenHeight();
  const [isGeneric, setIsGeneric] = useState(false);

  const uploadLogo = () => {
    document.getElementById("file").click();
  };

  const handleChange = (e) => {
    setLogo(e.target.files[0]);
    e.target.value = "";
  };

  const handleClick = async () => {
    const body = new FormData();
    body.append("image", logo);

    let nextStepUrl = "/prize-info";

    if (isGeneric) {
      nextStepUrl = "/setup-seals";
      const repository = new StoreRepository();
      await repository.partialUpdate({
        category_description: GENERIC_CATEGORY,
      });
    }

    if (logo) {
      new StoreRepository()
        .uploadLogo(body)
        .then((data) => {
          if (data) history.push(nextStepUrl);
          else console.log("error al cargar logo");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      history.push(nextStepUrl);
    }
  };

  useEffect(() => {
    new StoreRepository()
      .getStore()
      .then((data) => {
        setStore(data);
        if (data.configuration_completed) {
          history.push("/dashboard");
          return;
        }
        setUrlLogo(data.url_logo);
        setIsGeneric(data.store_category_id?.name === GENERIC_CATEGORY);
        // setTimeout(
        //   () =>
        //     (catDescriptionRef.current.value = data.category_description || ""),
        //   350
        // );
      })
      .catch((error) => console.log(error));
  }, []);

  const removeIcon = () => {
    setLogo(null);
    setUrlLogo(null);
  };

  return (
    <div
      className="bg-color-base"
      style={{
        maxHeight: screenHeight,
        height: screenHeight,
        overflow: "hidden",
      }}
    >
      <Row className="d-flex align-items-center">
        <Col lg={{ size: 6 }} xs={12} sm={12}>
          <div
            className="left-side"
            style={isDesktop ? {} : { maxHeight: screenHeight }}
          >
            <ProfileSidePanel
              isActive={1}
              image={BeeMockUp}
              imgClass={"profileImg"}
              isGeneric={isGeneric}
              urlLogo={urlLogo}
              store={store}
              logo={logo}
              uploadLogo={uploadLogo}
              removeIcon={removeIcon}
            />
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </div>
        </Col>
        {isDesktop && (
          <Col lg={{ size: 6 }} xs={12} sm={12}>
            <PrizeRightSide
              title={`¡Creemos juntos nuestro\nPlan de Lealtad!`}
              handleClick={handleClick}
              isLogo={!!logo || !!urlLogo}
              step={1}
              nextButton={true}
            />
          </Col>
        )}
        {!isDesktop && (
          <div className="fixed-bottom w-100 bottom-movil-detail">
            <div className="text-center my-3 d-flex align-items-center justify-content-center">
              <button
                onClick={handleClick}
                className={`laststep-button`}
              >
                Último Paso
                <i className="fa-solid fa-arrow-right text-white ms-2"></i>
              </button>
            </div>
          </div>
        )}
      </Row>
    </div>
  );
};

ProfileDetails.propTypes = {
  history: PropTypes.object,
};

export default ProfileDetails;
