import React, { useState, useEffect } from "react";
import { Button, Input, Row, Col, Container } from "reactstrap";
import { useParams } from "react-router-dom";
import alertCircle from "../../assets/images/logo/alert-circle-outline.png";
import "../../assets/scss/custom/pages/_prizesetup-one.scss";
import { PrizeRepository } from "../../repositories/prize";
import { PrizeOptionsRepository } from "../../repositories/prizeOptions";
import { numberWithCommas } from "../../helpers/funtions";
import { useCheckPaymentMethod } from "../../app/hook/useCheckPaymentMethod";
import "./index.css";
import beeLogo from "../../assets/images/LOGO.png";
import { Link } from "react-router-dom";
import PrizeRightSide from "../../components/PrizeSetUp";
import PrizeCard from "../../components/PrizeCard";
import { useIsDesktop } from "../../helpers/useIsDesktop";
import { useScreenHeight } from "../../helpers/useScreenHeight";
import { ContainerBottom } from "../Helpers/ContainerBottom";
import { useDispatch, useSelector } from "react-redux";
import { loadCardConfig } from "../../app/store/slices/store/cardConfig/effects";

let MAX_SETUP = 3;
let CONFIG_STEP = [];
let prize;
const PrizeSetUp = (props) => {
  ////useCheckPaymentMethod();
  const { history } = props,
    params = useParams(),
    pageNumber = Number(params.pageNumber) || 1,
    [newOption, setNewOption] = useState(""),
    [configStep, setConfigStep] = useState({}),
    [options, setOptions] = useState([]),
    [checkedOptions, setCheckedOptions] = useState([]),
    [checkedInitialOptions, setCheckedInitialOptions] = useState([]);

  const isDesktop = useIsDesktop();
  const screenHeight = useScreenHeight();
  const dispatch = useDispatch();
  const intl = new Intl.NumberFormat();

  useEffect(() => {
    dispatch(loadCardConfig());
  }, [dispatch]);

  const cardConfig = useSelector(
    (state) => state.storeSubscription.cardConfig.items
  );

  useEffect(() => {
    setCheckedOptions([]);
    setCheckedInitialOptions([]);

    const repository = new PrizeRepository();
    const optionsRepository = new PrizeOptionsRepository();
    repository
      .listByStoreCategoryIdAndCountryID()
      .then((data) => {
        MAX_SETUP = data.length;

        CONFIG_STEP = data.map((item) => ({
          id: item.id,
          amount: item.country_id.currency + numberWithCommas(item.amount),
          name: item.name,
          prize_number: Number(item.prize_number),
          example: item.example,
        }));

        if (CONFIG_STEP.length > pageNumber - 1) {
          let _prize = CONFIG_STEP.filter(
            (item) => item.prize_number === pageNumber
          );

          _prize = _prize.length ? _prize[0] : CONFIG_STEP[pageNumber - 1];
          prize = _prize;
          prize.id = Number(prize.id);
          setConfigStep(_prize);
          optionsRepository
            .listPrizeIdOrStoreId(_prize.id)
            .then((options) => {
              let checked = options
                .filter((item) => item.checked)
                .map((item) => item.id);

              setCheckedOptions([...checked]);
              setCheckedInitialOptions([...checked]);
              setOptions(
                options.map((option) => ({
                  id: option.id,
                  key: option.name,
                  checked: option.checked || false,
                }))
              );
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, [pageNumber]);

  const handleCheckBoxAdd = () => {
    if (newOption.length) {
      const optionsRepository = new PrizeOptionsRepository();
      optionsRepository
        .registerCustomOption({
          name: newOption,
          prize_id: prize.id,
        })
        .then((data) => {
          let newOpt = {
            id: data.id,
            key: newOption,
            checked: true,
          };
          setOptions([...options, newOpt]);
          setNewOption("");
          setCheckedOptions([...checkedOptions, newOpt.id]);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChecked = (checked, item) => {
    if (checked) {
      setCheckedOptions([...checkedOptions, item.id]);
    } else {
      setCheckedOptions(checkedOptions.filter((it) => it !== item.id));
    }
    setOptions(
      options.map((i) => {
        return i.id === item.id ? { ...item, checked: !i.checked } : i;
      })
    );
  };

  const handleNextRoute = () => {
    if (
      checkedOptions.length !== checkedInitialOptions.length ||
      checkedOptions.filter((item) => !checkedInitialOptions.includes(item))
        .length > 0
    ) {
      const optionsRepository = new PrizeOptionsRepository();
      optionsRepository
        .registerSelectedOptions({
          prize_id: prize.id,
          options: checkedOptions,
        })
        .then((_) => {
          if (isDesktop) {
            pageNumber === MAX_SETUP
              ? history.push("/wlcm-two")
              : history.push(`/prize-setup/${pageNumber + 1}`);
          } else {
            pageNumber === MAX_SETUP
              ? history.push("/wlcm-two")
              : history.push(`/prize-setup/${pageNumber + 1}`);
          }
        })
        .catch((error) => console.log(error));
    } else {
      if (isDesktop) {
        pageNumber === MAX_SETUP
          ? history.push("/wlcm-two")
          : history.push(`/prize-setup/${pageNumber + 1}`);
      } else {
        pageNumber === MAX_SETUP
          ? history.push("/wlcm-two")
          : history.push(`/prize-setup/${pageNumber + 1}`);
      }
    }
  };

  const changeAmountSeals = () => {
    if (pageNumber === 1) {
      return (
        (cardConfig.amountToFinishIt / cardConfig.sealQuantity) *
        cardConfig.PrizeOneSealNumber
      );
    }
    if (pageNumber === 2) {
      return (
        (cardConfig.amountToFinishIt / cardConfig.sealQuantity) *
        cardConfig.PrizeTwoSealNumber
      );
    }
    if (pageNumber === 3) {
      return (
        (cardConfig.amountToFinishIt / cardConfig.sealQuantity) *
        cardConfig.PrizeThreeSealNumber
      );
    }
  };

  return (
    <>
      {isDesktop && (
        <div
          className="container-prize"
          style={{
            minHeight: screenHeight * 1.2,
          }}
        >
          <Row className="m-0 p-0 align-items-center">
            <Col lg={6}>
              <Container
                className="left-side"
                style={{
                  minHeight: screenHeight * 1.2,
                }}
              >
                <div>
                    <Row>
                      <Col className="my-5">
                        <img src={beeLogo} alt="Logo Bee" />
                      </Col>
                    </Row>
                </div>
                <div>
                    <Col className="mt-2">
                      <span className="ms-2 subtitle">
                        Premio #{pageNumber}
                      </span>
                      <p className="description">
                        Selecciona los premios que estarías dispuesto a darle a
                        un
                        <br />
                        cliente que ha consumido {cardConfig.currency}{intl.format(changeAmountSeals())} en tu negocio:
                      </p>
                    </Col>
                </div>
                <div>
                    <div className="option-container">
                      {options.map((option) => (
                        <div
                          key={option.id}
                          className="checkbox-listing item-prize"
                        >
                          <label className="checkbox-container">
                            <input
                              type="checkbox"
                              id={option.id}
                              name={option.id}
                              checked={option.checked}
                              disabled={
                                !checkedOptions.includes(option.id) &&
                                checkedOptions.length > 2
                              }
                              onChange={(event) =>
                                handleChecked(event.target.checked, option)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="option-name" htmlFor={option.id}>
                            {option.key}
                          </label>
                        </div>
                      ))}
                    </div>
                </div>
                <Row className="mt-5">
                  <Col lg={{ size: 10 }}>
                    <div>
                      <p
                        className="description fw-bold"
                        style={{ fontSize: "18px" }}
                      >
                        ¿Quieres dar algo que no esta entre las opciones?
                        <br />
                        Agrega tu propio premio
                      </p>
                    </div>
                  </Col>
                  <div className="add-option-continer">
                    <Input
                      type="text"
                      name="addOption"
                      placeholder="Nombre del Premio"
                      className="input-add-options ps-3 py-2"
                      value={newOption}
                      onChange={(event) => setNewOption(event.target.value)}
                      disabled={!(checkedOptions.length < 3)}
                    />

                    <Button
                      disabled={!(checkedOptions.length < 3)}
                      onClick={handleCheckBoxAdd}
                      className="add-button"
                    >
                      Agregar +
                    </Button>
                  </div>
                  <Col lg={{size: 10 }} className="mt-3">
                    <span
                      className="award-content-span description"
                      style={{ color: "#858A96" }}
                    >
                      Por ejemplo: {configStep.example}
                    </span>
                    <div className="">
                      <p className="info pt-3 d-flex align-items-center">
                        <img
                          src={alertCircle}
                          alt="alert-circle"
                          style={{ transform: "scale(0.7)" }}
                        />{" "}
                        Debes seleccionar mínimo 1 opción y máximo 3 opciones
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5 h-25 d-flex align-items-center">
                  <Col
                    sm="3"
                    lg={{  size: 5 }}
                    // style={{ position: "absolute", left: 0, bottom: "25%" }}
                    // className={"mt-5"}
                  >
                    <Link to="#" onClick={() => window.history.back()}>
                      <button type="button" className="register-back-button">
                        Regresar
                      </button>
                    </Link>
                  </Col>
                  <Col
                    sm="3"
                    lg="5"
                    className="d-flex justify-content-end"
                    // style={{
                    //   position: "absolute",
                    //   right: "10%",
                    //   bottom: "25%",
                    // }}
                  >
                    <Button
                      onClick={handleNextRoute}
                      disabled={!(checkedOptions.length >= 1)}
                      className="button-continue-prizestup px-5"
                    >
                      Continuar
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col lg={6}>
              <PrizeRightSide
                title={`¡Construye un plan de\nlealtad a medida!`}
                step={2}
                nextButton={false}
              />
              <PrizeCard category={pageNumber} />
            </Col>
          </Row>
        </div>
      )}
      {!isDesktop && (
        <div
          style={{
            height: screenHeight,
            maxWidth: window.innerWidth,
            overflowX: "hidden",
          }}
          className="container-prize"
        >
          <Row>
            <Col xs={12}>
              <p className="title text-center mt-5 mb-0">
                Construye tu plan de
                <br /> lealtad a medida
              </p>
              <p className="title text-center" style={{ color: "#999999" }}>
                Premio #{pageNumber}
              </p>
            </Col>
            <Col xs={12} className="mt-0">
              <PrizeCard category={pageNumber} />
            </Col>
          </Row>

          <ContainerBottom>
            <Row>
              <Col xs={12} className="d-flex justify-content-center mt-5">
                <span className="text-center text-choose">
                  Elige premios para clientes que han consumido
                  <br />
                  {configStep.amount} en tu negocio:
                </span>
              </Col>
            </Row>
            <Container>
              <div className="option-container">
                {options.map((option) => {
                  return (
                    <div
                      className={`item-prize ${
                        option.checked ? "option-checked" : ""
                      }`}
                    >
                      <Input
                        type="checkbox"
                        className=""
                        id={option.id}
                        name={option.id}
                        checked={option.checked}
                        disabled={
                          !checkedOptions.includes(option.id) &&
                          checkedOptions.length > 2
                        }
                        style={{ display: "none" }}
                        onChange={(event) =>
                          handleChecked(event.target.checked, option)
                        }
                      />
                      <label htmlFor={option.id} className="option-label">
                        {option.key}
                      </label>
                    </div>
                  );
                })}
              </div>
            </Container>
            <Row className="mt-2">
              <Col xs={12}>
                <div className="px-3">
                  <p className="description-question">
                    ¿Quieres dar algo que no esta entre las opciones?
                    <br />
                    Agrega tu propio premio
                  </p>
                </div>
              </Col>
              <div>
                <Container>
                  <div className="custom-option-container">
                    <Input
                      type="text"
                      name="addOption"
                      placeholder="Nombre del Premio"
                      className="input-add-options ps-3 py-2"
                      value={newOption}
                      onChange={(event) => setNewOption(event.target.value)}
                      disabled={!(checkedOptions.length < 3)}
                    />
                    <Button
                      disabled={!(checkedOptions.length < 3)}
                      onClick={handleCheckBoxAdd}
                      className="add-button btn btn-lg"
                    >
                      Agregar +
                    </Button>
                  </div>
                </Container>
              </div>
              <Col xs={12} className="px-5">
                <span
                  className="award-content-span"
                  style={{ fontSize: "8px", color: "#999" }}
                >
                  Por ejemplo: {configStep.example}
                </span>
                <div>
                  <p
                    className="pt-1"
                    style={{ fontSize: "8px", color: "#999" }}
                  >
                    <img
                      src={alertCircle}
                      alt="alert-circle"
                      style={{ transform: "scale(0.5)" }}
                    />
                    Debes seleccionar mínimo 1 opción y máximo 3 opciones
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col
                xs={12}
                className="m-0 p-0 d-flex justify-content-center mt-3"
              >
                <div className="line_button mx-2">
                  <Button
                    onClick={handleNextRoute}
                    className="button-continue btn btn-lg px-5"
                    disabled={!(checkedOptions.length >= 1)}
                  >
                    CONTINUAR
                    <i className="fa-solid fa-arrow-right text-white ms-2"></i>
                  </Button>
                </div>
              </Col>
              <Col xs={12}>
                <Link to="#" onClick={() => window.history.back()}>
                  <button
                    type="button"
                    className="register-back-button mt-1 pb-5"
                  >
                    Regresar
                  </button>
                </Link>
              </Col>
            </Row>
          </ContainerBottom>
        </div>
      )}
    </>
  );
};

export default PrizeSetUp;
