import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import checkBlue from "../../../../assets/images/check-blue.svg";
import bgImg from "../../../../assets/images/register_manager.png";
import beeLogo from "../../../../assets/images/simple_logo.svg";

import "./index.css";
import { DotIndicator } from "./Dots";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { Link } from "react-router-dom";
import { TermAndCondition } from "../../TermAndCondition";
import { useIsDesktop } from "../../../../helpers/useIsDesktop";
import { useScreenHeight } from "../../../../helpers/useScreenHeight";
import { useScreenWidth } from "../../../../helpers/useScreenWidth";
import { rowItems } from "../../../../data/free-data";
import { RowItem } from "./RowItems";

const LineItem = ({ text }) => {
  return (
    <div
      className="d-flex justify-content-start align-items-center mt-3"
      style={{ gap: 20 }}
    >
      <img src={checkBlue} alt="check" />
      <span className="register-store-list-item">{text}</span>
    </div>
  );
};

const onNavigateTermsConditions = () => {
  window.open('https://beeloyalcard.com/terminos-condiciones-bee/', '_blank')
}

const StoreForm = ({ onBack, onSave }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm();
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [modal, setModal] = useState(false);
  const password = watch("password");
  const isDesktop = useIsDesktop();

  const validatePasswordConfirm = (value) => {
    if (value !== password) {
      return "Las contraseñas no coinciden";
    }
    return true;
  };

  const handleSave = (data) => {
    if (acceptConditions) {
      onSave(data);
    }
  };

  return (
    <form>
      <FormGroup>
        <Label className="register-input-label">Nombre del Encargado</Label>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field: { ref, ...params } }) => (
            <Input
              {...params}
              innerRef={ref}
              type="text"
              placeholder="Persona encargada del comercio"
              className="register-input"
              invalid={errors.name}
            />
          )}
        />
        {errors.name && (
          <span className="text-danger">Ingrese un nombre valido</span>
        )}
      </FormGroup>
      <FormGroup className={`${isDesktop ? "mt-4" : "mt-1"}`}>
        <Label className="register-input-label">Número del Encargado</Label>
        <Controller
          name="phone_manager"
          control={control}
          rules={{
            required: true,
            minLength: 8,
            pattern: {
              value: /^(0|[1-9]\d*)(\.\d+)?$/
            }
          }}
          defaultValue=""
          render={({ field: { ref, ...params } }) => (
            <Input
              {...params}
              innerRef={ref}
              type="tel"
              placeholder="Teléfono del encargado"
              className="register-input"
              invalid={errors.store_category}
            />
          )}
        />
        {errors.phone_manager && (
          <span className="text-danger">
            Ingrese un numero de teléfono valido
          </span>
        )}
      </FormGroup>
      <FormGroup className={`${isDesktop ? "mt-4" : "mt-1"}`}>
        <Label className="register-input-label">Contraseña</Label>
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field: { ref, ...params } }) => (
            <Input
              {...params}
              innerRef={ref}
              type="password"
              placeholder="Escribe tu contraseña"
              className="register-input"
              invalid={errors.password}
            />
          )}
        />
        {errors.password && (
          <span className="text-danger">
            Ingrese una contraseña valida. Al menos 6 carácteres.
          </span>
        )}
      </FormGroup>
      <FormGroup className={`${isDesktop ? "mt-4" : "mt-1"}`}>
        <Label className="register-input-label">Confirmar Contraseña</Label>
        <Controller
          name="password_confirm"
          control={control}
          rules={{ required: true, validate: validatePasswordConfirm }}
          defaultValue=""
          render={({ field: { ref, ...params } }) => (
            <Input
              {...params}
              innerRef={ref}
              type="password"
              placeholder="Vuelve a escribir tu contraseña"
              className="register-input"
              invalid={errors.password_confirm}
            />
          )}
        />
        {errors.password_confirm && (
          <span className="error-message text-danger">
            {errors.password_confirm.message}
          </span>
        )}
      </FormGroup>

      <Row>
        <Col sm="12" className="">
          <div className="d-flex align-items-center justify-content-start">
            <input
              type="checkbox"
              style={{ height: "1rem", width: "1rem" }}
              onClick={(e) => setAcceptConditions(e.target.checked)}
            />
            <div className="text-muted ms-2 mt-1 payment-form-tyc">
              Acepto los{" "}
              <Link
                to="#"
                onClick={() => onNavigateTermsConditions()}
                className="text-primary link_text"
              >
                Términos y Condiciones
              </Link>{" "}
            </div>
          </div>
        </Col>
      </Row>

      {isDesktop ? (
        <Row className={`d-flex ${isDesktop ? "pt-lg-5 " : "pt-lg-3"}`}>
          <Col sm="12" lg="6">
            <button
              type="button"
              className="register-back-button mt-3"
              onClick={onBack}
            >
              Regresar
            </button>
          </Col>
          <Col
            sm="12"
            lg="6"
            className={
             "d-flex justify-content-end"
            }
          >
            <button
              type="button"
              className="register-buttom-third"
              disabled={!acceptConditions}
              onClick={handleSubmit(handleSave)}
            >
              Finalizar Registro <i className="fas fa-arrow-right" />
            </button>
          </Col>
        </Row>
      ) : (
        <Row className={`d-flex ${isDesktop ? "pt-lg-5 " : "pt-lg-3"}`}>
          <Col
            sm="12"
            lg="6"
            className={"d-flex align-items-center justify-content-center"
            }
          >
            <button
              type="button"
              className="register-buttom-third mt-5"
              disabled={!acceptConditions}
              onClick={handleSubmit(handleSave)}
            >
              Finalizar Registro <i className="fas fa-arrow-right" />
            </button>
          </Col>
          <Col sm="12" lg="6">
            <button
              type="button"
              className="register-back-button"
              onClick={onBack}
            >
              Regresar
            </button>
          </Col>
        </Row>
      )}
      {/* <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        size="xl"
        centered
        data-toggle="modal"
        toggle={() => {
          setModal(!modal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setModal(!modal);
            }}
          >
            Terminos y Condiciones
          </ModalHeader>
        </div>
        <ModalBody>
          <TermAndCondition />
        </ModalBody>
      </Modal> */}
    </form>
  );
};

export const CreateAccountManagerInfo = ({ onNextStep, onBack }) => {
  const isDesktop = useIsDesktop();
  const heigth = useScreenHeight();

  const styles = isDesktop
    ? {
        maxHeigth: heigth,
        minHeight: heigth,
        maxWidth: window.innerWidth,
        overflow: "hidden"
      }
    : {
        overflowX: "hidden",
        height: heigth
      };

  return (
    <Container fluid className="register" style={styles}>
    <Row>
      <Col
        lg={6}
        className={`register-store-info-left shadow ${
          isDesktop ? "p-5" : "pt-3 px-5"
        }`}
        style={{ minHeight: window.innerHeight }}
      >
        <Row>
          {isDesktop && (
            <Col sm="12">
              <figure style={{ width: "7.63rem", height: "2.69rem" }}>
                <img src={beeLogo} alt="" className="img-fluid" />
              </figure>
            </Col>
          )}
          <Col sm="12">
            <h4 className="mt-4 register-store-create-title">
            {isDesktop ? (
                `${String.fromCharCode(218)}ltimos datos`
              ) : (
                <>
                  Super rápido y
                  <br />
                  fácil de usar
                </>
              )}
            </h4>
            {!isDesktop && (
              <h3
                className="mt-1 register-store-create-title"
                style={{ color: "#999999", fontSize: "16px" }}
              >
                &Uacute;ltimos datos
              </h3>
            )}
          </Col>
          <Col sm="12" lg="10">
            <div className={`${isDesktop ? "mt-4" : "mt-2"}`}>
              <StoreForm onBack={onBack} onSave={onNextStep} />
            </div>
          </Col>
          {isDesktop && (
            <Col sm="12" className="d-flex mt-5 py-3">
              <div>
                <DotIndicator maxDots={3} dots={3} />
              </div>
            </Col>
          )}
        </Row>
      </Col>
      {isDesktop && (
        <Col lg={6} style={{ height: heigth, maxHeight: heigth }}>
          <Row className="mt-5">
            <Col sm="12" lg={{ offset: 1, size: 10 }}>
              <div>
                <h1
                  className={`register-store-info-title ${
                    isDesktop ? "" : "text-center"
                  }`}
                >
                  Super rápido y{isDesktop ? <br /> : " "}
                  fácil de usar
                </h1>
              </div>
            </Col>
            <Col
              sm="12"
              lg={{ offset: 1, size: 10 }}
              className={`${isDesktop ? "mt-3" : ""}`}
            >
              <div>
                <LineItem text="Gana nuevos clientes" />
                <LineItem text="Tus clientes compran más en cada compra" />
                <LineItem text="Te visitan más seguido" />
              </div>
            </Col>
            <Col sm="12" className="mt-5">
              <figure className="manager-img mt-5">
                <img src={bgImg} alt="" className="img-fluid" />
              </figure>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  </Container>
  );
};
